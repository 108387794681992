<template>
  <div class="splash">
    <div class="splashbody">
      <div class="languages">
        <ul>
          <li v-for="language in languages.available" :key="language"><a @click="switchLanguage(language)">{{ language }}</a></li>
        </ul>
      </div>
      <div class="title">
        <p>{{ languages[langkey].splashtext }}</p>
      </div>
      <div class="form">
        <form @submit="showModal">
          <input type="text" :placeholder="languages[langkey].sendboxpre" v-model="shortdesc">
          <button>{{ languages[langkey].sendbutton }}</button>
        </form>
      </div>
    </div>
    <div class="engagescroll"><p>{{ languages[langkey].engagescroll }}</p></div>
  </div>

  <div class="who">
    <div class="title">
      <p>{{ languages[langkey].whoarewetitle }}</p>
    </div>
    <div class="content">
      <p>{{ languages[langkey].whoarewe }}</p>
    </div>
  </div>


  <div class="who forwho">
    <div class="title">
      <p>{{ languages[langkey].forwhotitle }}</p>
    </div>
    <div class="content">
      <p>{{ languages[langkey].forwho }}</p>
    </div>
  </div>


  <div class="who">
    <div class="title">
      <p>{{ languages[langkey].proceduretitle }}</p>
    </div>
    <div class="content">
      <p>{{ languages[langkey].procedure }}</p>
    </div>
    <div class="button">
      <button @click="showModal">{{ languages[langkey].procedurebutton }}</button>
    </div>
  </div>

  <div class="footer">
    <div class="infos">
      <div class="left">
        <div class="title"><p>{{ languages[langkey].contact }}</p></div>
        <div class="content">
          <ul>
            <li>Asterisque</li>
            <li><a href="mailto:hello@asterisque.dev">hello@asterisque.dev</a></li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="title"><p>{{ languages[langkey].theenterprise }}</p></div>
        <div class="content">
          <ul>
            <li><a href="">{{ languages[langkey].privacypolicy }}</a></li>
            <li><a href="">{{ languages[langkey].generalandcondition }}</a></li>
            <!--<li><a href="">{{ languages[langkey].jobs }}</a></li>-->
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright"><p>{{ languages[langkey].copyright }}</p></div>
  </div>

  <transition name="fade">
    <div class="modalblurback" v-if="modal"></div>
  </transition>
  <transition name="slide-fade">
    <div class="modalback" v-show="modal">
      <div class="modal">
        <form @submit="sendMail">

          <div class="namefirstname">
            <div class="input">
              <label for="name">{{ languages[langkey].name }}</label>
              <input @input="error.name = false" :class="{ 'error' : error.name }" type="text" id="name" name="name" v-model="name" placeholder="Appleseed">
            </div>

            <div class="input">
              <label for="prenom">{{ languages[langkey].firstname }}</label>
              <input @input="error.firstname = false" :class="{ 'error' : error.firstname }" type="text" id="firstname" name="firstname" v-model="firstname" placeholder="John">
            </div>
          </div>

          <div class="input">
            <label for="mail">{{ languages[langkey].mail }}</label>
            <input @input="error.mail = false" :class="{ 'error' : error.mail }" type="text" id="mail" name="mail" v-model="mail" placeholder="john.appleseed@mail.com">
          </div>

          <div class="input">
            <label for="description">{{ languages[langkey].shortdesc }}</label>
            <input @input="error.shortdesc = false" :class="{ 'error' : error.shortdesc }" type="text" id="description" name="description" v-model="shortdesc" :placeholder="languages[langkey].sendboxpre">
          </div>

          <div class="input">
            <label for="details">{{ languages[langkey].details }}</label>
            <textarea @input="error.details = false" :class="{ 'error' : error.details }" id="details" name="details" v-model="details" :placeholder="languages[langkey].sendboxpre + '...'"></textarea>
          </div>

          <div class="bt">
            <button type="reset" @click="closeModal">{{ languages[langkey].resetbutton }}</button>
            <button type="submit">{{ languages[langkey].sendbutton }}</button>
          </div>
        </form>

      </div>
    </div>
  </transition>

  <transition name="popdown">
    <div v-if="formsenterror" class="messagepopup messageerror">
      <p>{{ languages[langkey].errorformnetwork }}</p>
    </div>
  </transition>

  <transition name="popdown">
    <div v-if="formerror" class="messagepopup messageerror">
      <p>{{ languages[langkey].errorinput }}</p>
    </div>
  </transition>

  <transition name="popdown">
    <div v-if="formsuccesssent" class="messagepopup">
      <p>{{ languages[langkey].formsentsuccess }}</p>
    </div>
  </transition>
</template>

<script>
import languagefile from "@/assets/language.json"

export default {
  name: 'App',
  data () {
    return {
      languages: languagefile,
      formsenterror: false,
      formsuccesssent: false,
      langkey: "EN",
      erroracktext: "an error occured, please retry",
      shortdesc: "",
      mail: "",
      name: "",
      firstname: "",
      details: "",
      modal: false,
      formerror: false,
      error: {
        name: false,
        firstname: false,
        mail: false,
        shortdesc: false,
        details: false
      }
    }
  },
  methods: {
    switchLanguage (lang) {
      this.langkey = lang
    },
    showModal (e) {
      e.preventDefault()
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.error = {
        name: false,
        firstname: false,
        mail: false,
        shortdesc: false,
        details: false
      }
    },
    sendMail (e) {
      e.preventDefault()
      let good = true;
      if (this.name.length < 2) {
        good = false;
        this.error.name = true;
      } else {
        this.error.name = false;
      }

      if (this.firstname.length < 2) {
        good = false;
        this.error.firstname = true;
      } else {
        this.error.firstname = false;
      }

      if (!this.validateEmail(this.mail)) {
        good = false;
        this.error.mail = true;
      } else {
        this.error.mail = false;
      }

      if (this.shortdesc.length < 2) {
        good = false;
        this.error.shortdesc = true;
      } else {
        this.error.shortdesc = false;
      }

      if (this.details.length < 2) {
        good = false;
        this.error.details = true;
      } else {
        this.error.details = false;
      }

      if (this.error.name || this.error.firstname || this.error.mail || this.error.details || this.error.shortdesc) {
        this.formerror = true;
        setTimeout(() => this.formerror = false, 5000);
      }

      if (good) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", `./mailsender/sendmail.php?subject=${this.shortdesc}&mail=${this.mail}&message=${this.details}&firstname=${this.firstname}&name=${this.name}`);
        xhr.send();
        xhr.responseType = "json";
        xhr.onload = () => {
          if (xhr.readyState == 4 && xhr.status == 200) {
            this.modal = false;
            this.formsuccesssent = true;
            setTimeout(() => this.formsuccesssent = false, 5000);
          } else {
            this.formsenterror = true;
            setTimeout(() => this.formsenterror = false, 5000);
          }
        };

      }
    },
    validateEmail (email) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
      {
        return true
      }
      return false
    }
  },
  mounted () {
    let userLang = navigator.language || navigator.userLanguage;
    switch (userLang) {
      case "fr": this.langkey = "FR"; break;
      case "de": this.langkey = "DE"; break;
      default: this.langkey = "EN";
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
  background: #1f2125;
  font-family: 'Inter', sans-serif;
  color: white;

  input, button {
    font-size: 18px;
  }
  button {
    padding: 20px;
    border-radius: 1rem;
    border: none;
    background-color: white;
  }
  button:hover {
    background-color: white;
    cursor: pointer;
  }

  .splash {
    background-image: url("@/assets/background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .splashbody {
      width: 100vw;
      height: 100vh;

      background: linear-gradient(to bottom, transparent 0%, #1f2125 99%, #1f2125 100%);
      .languages {
        ul {
          list-style-type: none;
          padding: 10px 0;
          display: flex;
          flex-direction: row;
          justify-content: right;
          li {
            padding: 0 10px;
            border-left: white 2px solid;

            a {
              text-decoration: none;
              color: white;
              cursor: pointer;
            }
          }
          li:first-child {
            border-left: none;
          }
        }
      }

      .title {
        p {
          font-size: 6vw;
          text-align: center;
          margin: 25vh 0 0 0;
        }
      }

      .form form {
        margin-top: 10vh;
        display: flex;
        flex-direction: row;
        justify-content: center;

        input {
          padding: 20px;
          width: 60vw;
          border-radius: 1rem;
          border: none;
        }

        button {
          margin-left: 10px;
        }
      }
    }
  }

  .who {
    padding: 100px 0;
    width: 80vw;
    margin: auto;
    p {
      margin: 40px 0;
      width: 80%
    }
    .title {
      font-size: 3vw;
    }
    .content {
      font-size: 4vw;
    }
    .button {
      margin-top: 40px;
    }
  }

  .forwho {
    .title, .content {
      text-align: right;
      display: flex;
      justify-content: right;
    }
    .content p {
      margin-top: 0;
    }
  }

  .engagescroll {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    font-size: 18px;
  }

  .footer {
    width: 80vw;
    margin: auto;

    .copyright {
      border-top: solid 2px white;
    }


    .infos {
      display: flex;
      flex-direction: row;
      padding-bottom: 20px;
      padding-top: 100px;

      .title {
        font-size: 20px;
      }

      .right {
        margin-left: calc(50% - 200px);
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          padding: 5px 0;

          a {
            text-decoration: none;
            color: white;
            cursor: pointer;
          }
        }
      }
    }
  }

  .modalblurback {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.2);
    top: 0;
    width: 100vw;
    height: 100vh;
    left:0;
    z-index: 1000;
  }
  .modalback {
    position: fixed;
    background-color: rgba(255, 255, 255, 0);
    top: 0;
    width: 100vw;
    height: 100vh;
    left:0;
    z-index: 2000;

    .modal {
      background-color: #1f2125;
      opacity: 1;
      width: 80vw;
      max-width: 450px;
      margin: auto;
      padding: 30px;
      border-radius: 1rem;
      margin-top: 50vh;
      transform: translateY(-50%);

      form {
        display: flex;
        flex-direction: column;

        label {
          color: #fff;
          margin-bottom: 5px;
        }

        input[type="text"],
        input[type="email"],
        textarea {
          padding: 0.5rem;
          border: solid 2px #1f2125;
          transition: border-color 0.2s ease-in-out;
          background-color: #fff;
          color: #1f2125;
          margin-bottom: 1rem;
          font-family: 'Inter', sans-serif;

          &:focus {
            outline: none;
            border-color: #1f2125;
          }

          &.error {
            border: solid 2px red;
          }
        }

        textarea {
          resize: none;
          min-height: 150px;
        }

        div.input {
          display: flex;
          flex-direction: column;

          input, textarea {
            background-color: rgba(255,255,255, .05);
            border-radius: .5em;
            transition: .2s ease;
            border: solid 2px #1f2125;
            color: #fff;
            font-size: 18px;

            &.error {
              border: solid 2px #ff0033;
            }

            &::placeholder {
              color: rgba(255,255,255,.3)
            }
          }
        }

        div.namefirstname {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          div {
            max-width: 45%;
          }
        }
        div.bt {
          display: flex;
          gap: 1rem;
          justify-content: right;

          button {
            padding: 0.5rem 1rem;
            background-color: #1f2125;
            color: #fff;
            border: none;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;

            &:hover {
              background-color: #333;
            }
          }

          button[type="submit"] {
            background-color: #fff;
            color: #1f2125;
            &:hover {
              background-color: #999;
            }
          }
        }
      }

    }
  }
}

@media screen and (max-width: 810px) {
  body {
    .who {
      .title p {
        font-size: 25px;
        width: 100%;
      }
      .content p {
        font-size: 30px;
        width: 100%;
      }
    }
    .forwho {
      .title, .content {
        justify-content: left;
        text-align: left;
      }
    }
  }
}
body, ul {
  margin: 0;
}
.slide-fade-enter-active {
  transition: all 0.3s;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.4s;
}

.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.messagepopup {
  position: fixed;
  top: 20px;
  z-index: 3000;
  width: 100%;
  display: flex;
  justify-content: center;

  p {
    margin: 0;
    background-color: rgb(31, 33, 37, 1);
    border-radius: 1rem;
    padding: .5rem 1rem;
  }

  &.messageerror {
    p {
      background-color: #ff0033;
    }
  }
}

.popdown-enter-active {
  transition: all 0.3s;
}

.popdown-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.popdown-enter-from,
.popdown-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
